<template>
  <div>
    <my-nav-bar
      title="数据分析"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      asd
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
export default {
  components: { myNavBar }

}
</script>

<style>

</style>
